<template>
  <div class="right-footer-tools" v-if="articleEditStore.showFootMenu" @click.stop="">
    <div class="group-text">
      <div class="text-sub pointer" :class="isMain?'text-sub-active':''" @click.stop="mainStyleBtn">
        <span class="text-sub-text " :class="isMain? 'text-active-color' :''" >正文</span>
      </div>
      <div class="text-sub sub-center pointer" :class="isSecond ?' text-sub-active':''" @click.stop="secondStyleBtn">
        <span class="text-sub-text" :class="isSecond ? 'text-active-color':''" >灰色</span>
      </div>
      <div class="text-sub pointer" :class="isTips?'text-sub-active':''" @click.stop="tipsStyleBtn">
        <span class="text-sub-text " :class="isTips?'text-active-blue':''" >醒目</span>
      </div>
    </div>
    <div class="group-icon group-center">
      <div class="icon-sub pointer" :class="isBold?'icon-active':''" @click.stop="boldStyleBtn">
        <a-image :preview="false" :width="11" :height="15" :src="isBold?'/b_active.png':'/b.png'" fit="contain" ></a-image>
      </div>
      <div class="icon-sub sub-center pointer" :class="isItalic?'icon-active':''" @click.stop="italicStyleBtn">
        <a-image :preview="false" :width="10" :height="15" :src="isItalic?'/i_active.png':'/i.png'" fit="contain" ></a-image>
      </div>
      <div class="icon-sub pointer" :class="isUnderLine?'icon-active':''" @click.stop="underlineStyleBtn">
        <a-image :preview="false" :width="12" :height="16" :src="isUnderLine?'/u_active.png':'/u.png'" fit="contain" ></a-image>
      </div>
    </div>
    <div class="group-icon">
      <div class="icon-sub pointer" :class="isLeft?'icon-active':''" @click.stop="leftStyleBtn">
        <a-image :preview="false" :width="16" :height="14" src="/left.png" fit="contain" ></a-image>
      </div>
      <div class="icon-sub sub-center pointer" :class="isCenter?'icon-active':''" @click.stop="centerStyleBtn">
        <a-image :preview="false" :width="16" :height="14" src="/center.png" fit="contain" ></a-image>
      </div>
      <div class="icon-sub pointer" :class="isRight?'icon-active':''" @click.stop="rightStyleBtn">
        <a-image :preview="false" :width="16" :height="14" src="/right.png" fit="contain" ></a-image>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, ref, computed} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";
  const articleEditStore=useArticleEditStore();

  const isMain=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalMain") > -1;
  })
  const isSecond=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalTips") > -1;
  })
  const isTips=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalEye") > -1;
  })
  const isBold=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalBold") > -1;
  })
  const isItalic=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalItalic") > -1;
  })
  const isUnderLine=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalUnder") > -1;
  })
  const isLeft=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalLeft") > -1;
  })
  const isCenter=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalCenter") > -1;
  })
  const isRight=computed(()=>{
    let part=articleEditStore.curPart;
    return part != null && part['cls'] != null && part.cls.indexOf("globalRight") > -1;
  })


  onMounted(() => { });
  // 主体文本，次奥文本，提示文本的样式
  const mainStyleBtn=()=>{
    articleEditStore.changeStyle("globalMain");
  };
  const secondStyleBtn=()=>{
    articleEditStore.changeStyle("globalTips");
  };
  const tipsStyleBtn=()=>{
    articleEditStore.changeStyle("globalEye");
  };

  // 粗体，斜体和下划线设置
  const boldStyleBtn=()=>{
    articleEditStore.changeStyle("globalBold");
  };
  const italicStyleBtn=()=>{
    articleEditStore.changeStyle("globalItalic");
  };
  const underlineStyleBtn=()=>{
    articleEditStore.changeStyle("globalUnder");
  };

  // 左对齐，居中对齐，右对齐样式
  const leftStyleBtn=()=>{
    articleEditStore.changeStyle("globalLeft");
  };
  const centerStyleBtn=()=>{
    articleEditStore.changeStyle("globalCenter");
  };
  const rightStyleBtn=()=>{
    articleEditStore.changeStyle("globalRight");
  };
</script>

<style scoped>
.right-footer-tools{
  width: 650px;
  height: 54px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  background: #FFFFFF;
  box-sizing: border-box;
}
.group-center{
  margin:0 8px;
}
.group-text{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 261px;
  height: 40px;
  background: #F2F2F2;
  border-radius: 8px;
  box-sizing: border-box;
}
.text-sub{
  width: 80px;
  height: 32px;
  border-radius: 6px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.text-sub-active{
  background: #FFFFFF;
}
.sub-center{
  margin:0 6px;
}
.text-sub-text{
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 18px;
  text-align: center;
  color: #222222;
  box-sizing: border-box;
}
.text-active-color{
  color: #9B9B9B;
}
.text-active-blue{
  color: #2079F2;
}
.group-icon{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px;
  width: 152px;
  height: 40px;
  background: #F2F2F2;
  border-radius: 8px;
  box-sizing: border-box;
}
.icon-sub{
  width: 44px;
  height: 32px;
  border-radius: 6px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.icon-active{
  background: #FFFFFF;
}
</style>
