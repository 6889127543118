<template>
  <div class="header-tool">
    <span class="header-title">我的文章（{{articleListStore.total}}）</span>
    <div class="btn">
      <span class="btn-text pointer"  @click.stop="addNew">新建</span>
    </div>
  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
  const articleListStore=useArticleListStore();
  const addNew = () => {
    articleListStore.addNewArticle()
  }
  onMounted(() => {  });

</script>

<style scoped>
.header-tool{
  height: 70px;
  background: #FFFFFF;
  border-bottom: 1px solid #F2F2F2;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-title{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #222222;
}
.btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 76px;
  height: 38px;
  background: #FF9929;
  border-radius: 8px;
  box-sizing: border-box;
}
.btn-text{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}
</style>
