<template>
  <div class="header-tool">
    <div class="header-left">
      <a-image :preview="false" :width="22" :height="22" src="/back.png" fit="contain" class="icon pointer" @click.stop="backBtn" ></a-image>
      <span class="header-title">编辑文章</span>
      <div class="do-con">
        <a-image :preview="false" :width="22" :height="22" src="/undo.png" fit="contain" class="icon pointer" @click.stop="undoBtn"></a-image>
        <a-image :preview="false" :width="22" :height="22" src="/redo.png" fit="contain" class="icon pointer" @click.stop="redoBtn"></a-image>
      </div>
    </div>
    <div class="btn">
      <span class="btn-text pointer"  @click.stop="saveBtn">完成</span>
    </div>

  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";
  import router from "@/router/index";

  const articleEditStore=useArticleEditStore();

  onMounted(() => {  });
  const backBtn=()=>{
    router.back();
  }
  const undoBtn=()=>{
    articleEditStore.undo();
  }
  const redoBtn=()=>{
    articleEditStore.redo();
  }
  const saveBtn=async()=>{
    await articleEditStore.submit();
  }
</script>

<style scoped>
.header-tool{
  width: 1000px;
  height: 70px;
  background: #FFFFFF;
  border-bottom: 1px solid #F2F2F2;
  padding: 19px 20px 19px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}
.header-left{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.do-con{
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 15px ;
  width: 104px;
  box-sizing: border-box;
}
.icon{
  width: 22px;
  height: 22px;
  color: #333333;
}
.btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 76px;
  height: 38px;
  background: #FF9929;
  border-radius: 8px;
  box-sizing: border-box;
}
.btn-text{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}
.header-title{
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  margin-left: 20px;
}
</style>
