
import { defineStore } from 'pinia'
import {createImagePart, createTextPart, createVideoPart} from "@/utils/partTools";
import {Message} from "@arco-design/web-vue";
import {api} from "@/utils/api";
import router from "@/router";
import {useUserStore} from "@/store/user";

export const useArticleEditStore = defineStore('articleEdit', {
    persist: {
        enabled: true, //开启存储
        storage:localStorage,

    },
    state: () => {
        return {
            // new=新建；copy=拷贝；edit=编辑
            curType:"",
            loading:false,
            historyId:0,
            history:[],

            curArticle: {},

            //curBase: {},
            curArt:{},
            curParts: [],

            curPartId:0,
            curPart:{},

            //是否显示底部字体编辑样式。
            showFootMenu:false
        }
    },
    getters: {},
    actions: {
        // 选择段落
        selectPart(idx){
            this.curPartId=idx;
            this.curPart=this.curParts[idx];
        },

        // undo操作
        undo() {
            if(this.historyId===0){
                Message.warning("已不能再进行撤销操作")
            }else{
                this.historyId-=1;
                let data=this.history[this.historyId];
                this.curArt=data.art;
                this.curParts=data.parts;

            }
        },

        // redo 操作
        redo() {
            if(this.historyId===(this.history.length-1)){
                Message.warning("已不能再进行重做操作")
            }else{
                this.historyId+=1;
                let data=this.history[this.historyId];
                this.curArt=data.art;
                this.curParts=data.parts;
            }
        },
        // save 操作
        saveHistory(){
            let data={
                art:this.curArt,
                parts:this.curParts,
            }
            let _data=JSON.parse(JSON.stringify(data));
            if(this.history.length>=50){
                Message.error("当前历史记录已满，不能再保存更多的操作记录!")
                this.history.shift();
                this.history.push(_data);
                this.historyId=this.history.length-1;
            }else{
                this.history.push(_data);
                this.historyId=this.history.length-1;
            }
        },

        // 段落删除
        delPart(idx) {

            this.curParts.splice(idx,1);
            this.curPartId=null;
            this.curPart=null;
            this.saveHistory();
        },
        // 段落移动
        upPart(idx) {

            let parts=this.curParts.splice(idx,1);
            let part=parts[0];
            this.curParts.splice(idx-1,0,part);
            this.saveHistory();
        },
        downPart(idx) {
            let parts=this.curParts.splice(idx,1);
            let part=parts[0];
            this.curParts.splice(idx+1,0,part);
            this.saveHistory();
        },
        copyPart(idx){

            let part=JSON.parse(JSON.stringify(this.curParts[idx])) ;
            this.curParts.splice(idx+1,0,part);
            this.curPart=part;
            this.curPartId=idx+1;
            this.saveHistory();
        },
        // 文本段落插入
        insertTextPart(idx,content) {

            let part=createTextPart();
            part.content=content;
            this.curParts.splice(idx+1,0,part);
            this.curPartId=idx+1;
            this.curPart=part;
            this.saveHistory();
        },
        // 图片段落插入
        insertImagePart(idx,url) {

            let part=createImagePart();
            part.hightUrl=url;
            part.lowUrl=url;
            this.curParts.splice(idx+1,0,part);
            this.curPartId=idx+1;
            this.curPart=part;
            this.saveHistory();
        },
        // 视频段落插入
        insertVideoPart(idx,url,cover) {

            let part=createVideoPart();
            part.cover=cover;
            part.url=url;
            this.curParts.splice(idx+1,0,part);
            this.curPartId=idx+1;
            this.curPart=part;
            this.saveHistory();
        },

        // 更换图片
        switchImage(idx,url) {
            let part=this.curParts[idx];
            part.highUrl=url;
            part.lowUrl=url;
            this.saveHistory();
        },
        // 切换视频
        switchVideo(idx,videoUrl,coverUrl) {
            let part=this.curParts[idx];
            part.url=videoUrl;
            part.cover=coverUrl;
            this.saveHistory();
        },

        // 文本段落追加
        appendTextPart(content) {

            let part=createTextPart();
            part.content=content;
            this.curParts.push(part);
            this.curPartId=this.curParts.length-1;
            this.curPart=part;
            this.saveHistory();
        },
        // 图片段落追加
        appendImagePart(url) {

            let part=createImagePart();
            part.highUrl=url;
            part.lowUrl=url;
            this.curParts.push(part);
            this.curPartId=this.curParts.length-1;
            this.curPart=part;
            this.saveHistory();
        },
        // 视频段落追加
        appendVideoPart(videoUrl,coverUrl) {

            let part=createVideoPart();
            part.cover=coverUrl;
            part.url=videoUrl;
            this.curParts.push(part);
            this.curPartId=this.curParts.length-1;
            this.curPart=part;
            this.saveHistory();
        },
        // title 编辑
        titleEdit(content) {
            this.curArt.title=content;
            this.saveHistory();
        },
        // 上传封面
        uploadCover(url) {
            this.curArt.cover=url;
            this.saveHistory();
        },
        delCover() {
            this.curArt.cover=null;
            this.saveHistory();
        },
        // 文本信息编辑
        changeStyle(cls) {
            let mainCls=["globalMain","globalTips","globalEye"];
            let alignCls=["globalLeft","globalCenter","globalRight"];
            if(this.curPart==null){
                Message.error("尚未选择相关的段落")
            }else{
                let index=this.curPart.cls.indexOf(cls)
                if(index > -1){
                    // 删除对应样式
                    this.curPart.cls=this.curPart.cls.replace(cls,"");
                }else{
                    // 新增对应的样式
                    if(mainCls.indexOf(cls)>-1){
                        for(let i=0;i<mainCls.length;i++){
                            let _cls=mainCls[i];
                            this.curPart.cls=this.curPart.cls.replace(_cls,"");
                        }
                    }else if(alignCls.indexOf(cls)>-1){
                        for(let i=0;i<alignCls.length;i++){
                            let _cls=alignCls[i];
                            this.curPart.cls=this.curPart.cls.replace(_cls,"");
                        }
                    }
                    //
                    this.curPart.cls=this.curPart.cls+" "+cls;
                }
                this.saveHistory();
            }
        },

        // 编辑完成提交
        async submit() {
            this.loading=true;
            if(this.curType==="new"){
                await this.newSave();
            }else if(this.curType==="edit"){
                await  this.editSave();
            }
            this.loading=false;
            await router.back();
        },
        // curType="new" ；新建时候的保存
        async newSave(){
            try {
                if(this.curArt.title==null || this.curArt.title.trim().length===0){
                    this.curArt.title="未命名文章"
                }
                // 上传wpg
                let data={
                    art:this.curArt,
                    parts:this.curParts,
                };
                let rst=await api().wpg("/v1/upload").post({userId:1,data:JSON.stringify(data)});

                if(rst.code!=="ok"){
                    Message.error("Wpg文件上传失败，请稍后重试");
                }else{
                    // https://cheery-1304040337.cos.ap-shanghai.myqcloud.com/wpg/1/1_1673247517.wpg
                    const userStore=useUserStore();
                    let wpgUrl=rst.data.url;
                    let params={
                        userId:userStore.id,
                        file:wpgUrl,
                        title:this.curArt.title,
                        cover:this.curArt.cover,
                        ver:1.0
                    }
                    let wpgRst=await  api().url("/article/index").post(params);
                    if(wpgRst.code!=="ok"){
                        Message.error("数据入库失败");
                    }else{
                        Message.success("数据已成功入库");
                    }
                }
            }catch (e) {
                Message.error("网络请求失败，请稍后重试")
            }
        },
        //curType="edit" ；编辑时时候的更新
        async editSave(){
            try {
                let fileUrl=this.curArticle.file;
                if(this.curArt.title==null || this.curArt.title.trim().length===0){
                    this.curArt.title="未命名文章"
                }
                // 上传wpg
                let data={
                    art:this.curArt,
                    parts:this.curParts,
                };
                let rst=await api().wpg("/v1/update").post({url:fileUrl,data:JSON.stringify(data)});
                if(rst.code!=="ok"){
                    Message.error("Wpg文件上传失败，请稍后重试");
                }else{
                    // https://cheery-1304040337.cos.ap-shanghai.myqcloud.com/wpg/1/1_1673247517.wpg
                    let params={
                        id:this.curArticle.id,
                        file:fileUrl,
                        title:this.curArt.title,
                        cover:this.curArt.cover,
                        ver:1.0
                    }
                    let wpgRst=await  api().url("/article/update").post(params);
                    if(wpgRst.code!=="ok"){
                        Message.error("数据入库失败");
                    }else{
                        this.curArticle.title=this.curArt.title;
                        this.curArticle.cover=this.curArt.cover;
                        Message.success("数据已成功入库");
                    }
                }
            }catch (e) {
                console.log(e);
                Message.error("网络请求失败，请稍后重试")
            }
        },

    },
})
