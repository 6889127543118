<template>
  <div class="con">
    <span class="text" :class="item['cls']">
      {{item['content']}}
    </span>
  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"

  const articleListStore=useArticleListStore();
  const props = defineProps({
    item: Object
  })

  onMounted(() => {  });


</script>

<style scoped>
.con{
  width: 522px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 32px;
  box-sizing: border-box;
}
.text{
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  color: #222222;
}
</style>

