import axios from 'axios/index.js'
import {AesDecode, AesEncode} from "@/utils/cipher.js";
import {useRouter} from "vue-router";

const baseURL = 'https://websvr.jsyxgl.net';
const wpgURL = 'https://wpg.jsyxgl.net';
const pdfURL = 'https://pdf.jsyxgl.net';

const axiosApi = axios.create();

axios.defaults.baseURL = baseURL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.delete['Content-Type'] = 'application/json';
axios.defaults.timeout = 600000;
// 添加请求拦截器
const router = useRouter()

//  默认前置拦截加密
const before = function (data) {
    return AesEncode(JSON.stringify(data));
}
//  默认后置解密拦截
const after = function (data) {
    return AesDecode(data.data)
}

function api(crypt) {
    let Api = {
        _axios: axiosApi,
        isCrypto: false,
        appObj: {
            "url": null,
            "timeout": 60000, //60s
            "headers": {
                'content-type': 'application/json',
                "Authorization": ""
            },
            "dataType": "json"
        },
        url: (url) => {
            Api.appObj.url = baseURL + url;
            return Api;
        },
        // 更新:/v1/update;上传：/v1/upload;解析文章：/v1/article;
        wpg: (url) => {
            Api.appObj.url = wpgURL + url;
            return Api;
        },
        // orange的pdf生成:"/orange";cherry的pdf生成："/cherry"；返回的都是pdf的地址。
        pdf: (url) => {
            Api.appObj.url = pdfURL + url;
            return Api;
        },
        // 是否使用jwt token；默认是使用。
        token: (useToken) => {
            if (!useToken) {
                delete Api.appObj.headers.Authorization
            }
        },
        // 设置请求参数的类型；默认是json;可自行添加
        use: (tp) => {
            if (tp === 'query') {
                Api.appObj.headers["content-type"] = 'application/x-www-form-urlencoded';
            } else if (tp === 'json') {
                Api.appObj.headers["content-type"] = 'application/json';
            } else {
                throw new Error("输入请求头数类型错误！")
            }
            return Api;
        },
        // 返回数据的类型，默认是json，基本不用修改
        dataType: (tp) => {
            Api.appObj.dataType = tp;
            return Api;
        },
        // 设置请求超时时间
        timeout: (tm) => {
            Api.appObj.timeout = tm;
            return Api;
        },
        // Post方法
        post: async (data) => {
            Api.appObj.method = 'POST';
            return await Api.request(data)
        },
        // Get方法
        get: async (data) => {
            Api.appObj.method = 'GET';
            return await Api.request(data)
        },
        // Put方法
        put: async (data) => {
            Api.appObj.method = 'PUT';
            return await Api.request(data)
        },
        // Delete方法
        del: async (data) => {
            Api.appObj.method = 'DELETE';
            return await Api.request(data)
        },
        // 统一封装的实际请求
        request: async (data) => {
            return new Promise((resolve, reject) => {
                // 请求拦截；加密
                if (Api.isCrypto) {
                    Api.appObj.data = before(data);
                } else {
                    Api.appObj.data = data;
                }
                // 发送请求
                Api._axios.request(Api.appObj).then((res) => {
                    let status = res.status;
                    let data = res.data
                    if (status === 200) {
                        if (data.code === "tokenError") {
                            router.push({name: 'login'})
                            resolve(data);
                        } else {
                            // 返回拦截；解密
                            let back = null;
                            if (Api.isCrypto) {
                                let _back = JSON.parse(after(data));
                                data.data = _back;
                                back = data;
                            } else {
                                back = res.data;
                            }
                            if (back.code !== "ok") {
                                reject(back);
                            } else {
                                resolve(back);
                            }

                        }

                    } else {

                    }
                }).catch((err) => {
                    reject(err)
                });
            })
        }
    }
    return Api;
}

export {
    api
}
