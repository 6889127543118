<template>
  <a-spin :loading="articleEditStore.loading" tips="正在请求数据，请稍后">
    <div class="base">
      <div class="con">
        <Header></Header>
        <div class="main">
          <LeftCon></LeftCon>
          <RightCon></RightCon>
        </div>
      </div>
    </div>
  </a-spin>

</template>

<script setup>
import {  defineProps, defineEmits,onMounted} from 'vue'
import {useArticleEditStore} from "@/store/articleEdit"
import {useUserStore} from "@/store/user";
import Header from "@/pages/co/editCo/header.vue";
import LeftCon from "@/pages/co/editCo/left_con.vue";
import RightCon from "@/pages/co/editCo/right_con.vue";
import router from "@/router";

const articleEditStore=useArticleEditStore();
const userStore=useUserStore();

onMounted(async() => {
  articleEditStore.saveHistory();
  articleEditStore.curPartId=null;
  if(userStore.id==null){
    await router.push({name: 'login'});
  }
});

</script>

<style scoped>
.base{
  width:100vw;
  height:100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #E5E5E5;
}
.con{
  width: 1000px;
  height: 720px;
  background: #E0E0E0;
  border: 1px solid #828282;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}
.main{
  width: 100%;
  height: 650px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>
