<template>
  <div class="con">
    <div class="text-row">
      <span class="text">添加</span>
    </div>
    <div class="icon-row">
      <div class="icon-con">
        <div class="icon-sub" @click.stop="pushTextBtn">
          <div class="icon-sub-img pointer">
            <a-image :preview="false" :width="20" :height="16" src="/textIcon.png" fit="contain" ></a-image>
          </div>
          <div class="icon-sub-text" ><span>文字</span></div>
        </div>
        <div class="icon-sub second pointer">
          <a-upload
              action="/"
              :multiple="true"
              :limit="9"
              :auto-upload="false"
              ref="uploadImageRef"
              accept=".jpg, .jpeg, .png"
              :show-file-list="false"
              :show-remove-button="false"
              :show-retry-button="false"
              :show-cancel-button="false"
              :on-before-upload="uploadImage"
          >
            <template #upload-button>
              <div class="icon-sub-img">
                <a-image :preview="false" :width="20" :height="16" src="/imgIcon.png" fit="contain" ></a-image>
              </div>
            </template>
          </a-upload>
          <div class="icon-sub-text" ><span>图片</span></div>
        </div>
        <div class="icon-sub pointer">
          <a-upload
              action="/"
              :multiple="true"
              :limit="1"
              :auto-upload="false"
              ref="uploadVideoRef"
              accept=".mp4"
              :show-file-list="false"
              :show-remove-button="false"
              :show-retry-button="false"
              :show-cancel-button="false"
              :on-before-upload="uploadVideo"
          >
            <template #upload-button>
              <div class="icon-sub-img">
                <a-image :preview="false" :width="20" :height="16" src="/videoIcon.png" fit="contain" ></a-image>
              </div>
            </template>
          </a-upload>

          <div class="icon-sub-text" ><span>视频</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";
  import {Message} from "@arco-design/web-vue";
  import {uploadRes, uploadVideoCover} from "@/utils/cos";
  import {getVideoCover} from "@/utils/partTools";

  const articleEditStore=useArticleEditStore();

  onMounted(() => {  });

  // 尾部追加段落
  const pushTextBtn = () => {
    articleEditStore.appendTextPart(null);
  }
  const uploadImage=async(file)=>{
    articleEditStore.loading=true;
    if(file.size>=1024*1024){
      Message.error("图片文件最大上传为1MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("资源上传成功");
      let url= "https://" + rst.Location;
      articleEditStore.appendImagePart(url);
    }else{
      Message.error("资源上传错误");
    }
    articleEditStore.loading=false;
  }
  const uploadVideo=async(file)=>{
    articleEditStore.loading=true;
    if(file.size>=20*1024*1024){
      Message.error("视频文件最大上传为20MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("视频上传成功");
      let mp4Url= "https://" + rst.Location;
      getVideoCover(mp4Url,(file)=>{
        birthVideoCover(mp4Url,file);
      })
    }else{
      Message.error("视频上传错误");
    }
    articleEditStore.loading=false;
  }
  const birthVideoCover = async (mp4Url,file) => {
    let rst=await uploadVideoCover(1,file);
    if(rst.statusCode===200){
      Message.success("封面上传成功");
      let coverUrl= "https://" + rst.Location;
      articleEditStore.appendVideoPart(mp4Url,coverUrl);
    }else{
      Message.error("封面上传错误");
    }

  }

</script>

<style scoped>
.con{
  width: 650px;
  height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 50px;
  background-color: #FBFBFB;
  box-sizing: border-box;
  overflow: hidden;
}
.text-row{
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.text{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 2px;
  color: #BDBDBD;
}
.icon-row{
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icon-con{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 192px;
  height: 64px;
  box-sizing: border-box;
}
.icon-sub{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 44px;
  height: 64px;
}
.icon-sub-img{
  width: 44px;
  height: 44px;
  background: #FFFFFF;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.icon-sub-text{
  width: 24px;
  height: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #666666;
}
.second{
  margin: 0 30px;
}
</style>
