<template>
  <div class="right-footer-tools">
    <div>
      <a-image :preview="false" :width="24" :height="24" src="/delete.png" fit="contain" class="del-icon pointer" @click.stop="delBtn"></a-image>
      <a-image :preview="false" :width="24" :height="24" src="/copy.png" fit="contain" class="other-icon pointer" @click.stop="copyBtn"></a-image>
      <a-image :preview="false" :width="24" :height="24" src="/download.png" fit="contain" class="other-icon pointer" @click.stop="pdfBtn"></a-image>
    </div>
    <div class="edit-btn">
      <span class="edit-btn-text pointer" @click.stop="editBtn">编辑</span>
    </div>
  </div>
</template>

<script setup>
  import {  defineProps,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
  import {Message} from "@arco-design/web-vue";
  const emit=defineEmits(['onShowModal']);
  const articleListStore=useArticleListStore();
  onMounted(() => {  });
  // 编辑按钮
  const editBtn = () => {
    articleListStore.editArticle();
  }
  // 删除按钮
  const delBtn = () => {
    if(articleListStore.curArticle!=null){
      emit("onShowModal");
    }else{
      Message.error("尚未选择相关的文章")
    }

  }
  // 拷贝阿牛
  const copyBtn = async() => {
   await articleListStore.copyArticle();
  }
  // 下载pdf按钮
  const pdfBtn = () => {
    articleListStore.downPdf();
  }
</script>

<style scoped>
.right-footer-tools{
  width: 650px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left:20px;
  padding-right:35px;
  box-sizing:border-box;
  border-top: 1px solid #F2F2F2;
}

.del-icon{
  width: 24px;
  height: 24px;
  margin-left: 0;
  color: #EF544D;
}
.other-icon{
  width: 24px;
  height: 24px;
  margin-left: 24px;
  color: #333333;
}
.edit-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 76px;
  height: 38px;
  background: #FF9929;
  border-radius: 8px;
}
.edit-btn-text{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
}
</style>
