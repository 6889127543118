<template>
  <div class="right-con">
    <div class="right-list-con">
      <a-scrollbar type="track" outer-class="outer-class" v-if="articleListStore.curArticle!=null">
        <div class="right-list-display-con">
          <TitlePreview :item="articleListStore.curArticle"></TitlePreview>
          <div v-for="(item,index) in articleListStore.curParts" :key="index">
            <TextPreview v-if="item.tp==='text'" :item="item"></TextPreview>
            <ImagePreview v-if="item.tp==='image'" :item="item"></ImagePreview>
            <VideoPreview v-if="item.tp==='video'" :item="item"></VideoPreview>
          </div>
        </div>
      </a-scrollbar>
      <span class="right-empty-tips" v-if="articleListStore.curArticle==null">未选择文档</span>
    </div>
    <RightConFoot v-if="articleListStore.curArticle!=null" @onShowModal="onShowModal"></RightConFoot>
    <a-modal :visible="isShowModal" @ok="onOk" @cancel="onCancel" okText="确定" cancelText="取消" unmountOnClose>
      <template #title>删除确认</template>
      <div>您确定删除该文章么？删除后该文章将不可恢复。请慎重选择。</div>
    </a-modal>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, ref} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
  import RightConFoot from "@/pages/co/listCo/right_con_foot.vue";
  import TitlePreview from "@/pages/co/listCo/preview/title_preview.vue";
  import TextPreview from "@/pages/co/listCo/preview/text_preview.vue";
  import ImagePreview from "@/pages/co/listCo/preview/image_preview.vue";
  import VideoPreview from "@/pages/co/listCo/preview/video_preview.vue";


  const articleListStore=useArticleListStore();
  const props = defineProps({
  })
  const isShowModal=ref(false);
  const onShowModal=()=>{
    isShowModal.value=true;
  }
  const onOk=()=>{
    isShowModal.value=false;
    articleListStore.delArticle();
  }
  const onCancel=()=>{
    isShowModal.value=false;
  }
  onMounted(() => { });

</script>

<style scoped>
.right-con{
  width: 650px;
  height: 650px;
  background: #FBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.outer-class{
  height:600px;
  width:650px;
  overflow-x: hidden;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.right-list-con{
  width: 650px;
  height: 600px;
  background: #FBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing:border-box;
}
.right-empty-tips{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #C0C5CE;
}

.right-list-display-con{
  margin: 0 64px;
  background: #FBFBFB;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}
</style>
