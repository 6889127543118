<template>
  <div class="con">
    <div class="sub" :class="articleEditStore.curPartId===props.idx?'active':''">
        <div class="top">
            <div class="image-row">
                <div class="image-con">
                    <div class="video" >
                        <video
                                ref="videoRef"
                                playsinline class="video-js vjs-big-play-button  vjs-big-play-centered" >
                        </video>
                    </div>
                    <div class="left-col" @click.stop="delBtn">
                        <a-image :preview="false" :width="18" :height="18" src="/close.png" fit="contain" ></a-image>
                    </div>

                    <div class="right-col" >
                        <a-upload
                                action="/"
                                :multiple="false"
                                :auto-upload="false"
                                ref="uploadRef"
                                accept=""
                                :show-file-list="false"
                                :show-remove-button="false"
                                :show-retry-button="false"
                                :show-cancel-button="false"
                                :on-before-upload="uploadVideo"
                        >
                            <template #upload-button>
                                <a-image :preview="false" :width="18" :height="18" src="/switch.png" fit="contain" ></a-image>
                            </template>
                        </a-upload>

                    </div>
                </div>
            </div>

            <div class="btn-row" @click.stop="showInput" v-if="!tipsShow && !item.tips">
                <span class="btn">添加描述</span>
            </div>
            <a-textarea
                    v-if="tipsShow || item.tips"
                    style="margin-top: 10px"
                    :model-value="item.tips"
                    @blur="hideInput"
                    @focus="onfocus"
                    ref="tips"
                    @input="textInput"
                    auto-size :class="item.cls" />
        </div>

    </div>
      <div class="line"></div>
    <a-modal v-model:visible="isShow" @ok="delOK" @cancel="delCancel">
      <template #title>
        警告
      </template>
      <div>您确定要删除该段落么？</div>
    </a-modal>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, ref, onUnmounted, reactive, watch, computed, nextTick} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";
  import videojs from "video.js";
  import "video.js/dist/video-js.css"
import {Message} from "@arco-design/web-vue";
import {uploadRes, uploadVideoCover} from "@/utils/cos";
import {getVideoCover} from "@/utils/partTools";

  const articleEditStore=useArticleEditStore();
  const props = defineProps({
    idx: Number
  })
  const tips = ref(null);
  let tipsShow=ref(false)
  const  isShow=ref(false);
  const item=computed(()=>{
    return articleEditStore.curParts[props.idx]
  })
  if(item.tips!=null){
    tipsShow.value=true;
  }

  const videoRef=ref(null)
  const myPlayer = ref(null)
  const options=computed(()=>{
    let data=articleEditStore.curParts[props.idx];
    return {
      controls: false,
      poster:data.cover,
      autoplay:false,
      preload:"auto",
      aspectRatio:"4:3",
      width:"270px",
      src: data.url,
      controlBar: {
        fullscreenToggle: true,//显示全屏按钮，默认为true
        pictureInPictureToggle: false,//隐藏画中画按钮，默认为true
        volumePanel: false,//隐藏声音面板
        currentTimeDisplay: true,//显示当前播放时间
        timeDivider: true,//显示时间分割线
        durationDisplay: true,//显示总时间
        remainingTimeDisplay: false,//隐藏剩余时间，
      },
    }
  })


  onMounted(() => {
    myPlayer.value = videojs(videoRef.value,options.value , () => {
      myPlayer.value.poster(item.value.cover);
      myPlayer.value.src(item.value.url);
    });

  });
  onUnmounted(() => {
    if (myPlayer.value) {
      myPlayer.value.dispose()
    }
  })

  const textInput=(value,e)=>{
    let part=articleEditStore.curParts[props.idx];
    part.tips=value;
  }
const uploadVideo=async(file)=>{
  articleEditStore.loading=true;
  if(file.size>=20*1024*1024){
    Message.error("视频文件最大上传为20MB;当前文件已超过大小");
  }
  let rst=await uploadRes(1,file);
  if(rst.statusCode===200){
    Message.success("视频上传成功");
    let mp4Url= "https://" + rst.Location;
    getVideoCover(mp4Url,(file)=>{
      birthVideoCover(mp4Url,file);
    })
  }else{
    Message.error("视频上传错误");
  }
  articleEditStore.loading=false;
}
const birthVideoCover = async (mp4Url,file) => {
  let rst=await uploadVideoCover(1,file);
  if(rst.statusCode===200){
    Message.success("封面上传成功");
    let coverUrl= "https://" + rst.Location;
    articleEditStore.switchVideo(props.idx,mp4Url,coverUrl);
    myPlayer.value.poster(item.value.cover)
    myPlayer.value.src(item.value.url);
  }else{
    Message.error("封面上传错误");
  }
}
  const delOK=()=>{
    isShow.value=false;
    articleEditStore.delPart(props.idx);
  }
  // 功能按钮
  const delBtn = () => {
    isShow.value=true;
  }
  const delCancel = () => {
    isShow.value=false;
  }

  const showInput = () => {
      tipsShow.value=true;
      articleEditStore.selectPart(props.idx);
      nextTick(()=>{
          tips.value.focus()
      })
  }
  const onfocus=()=>{
      articleEditStore.selectPart(props.idx);
      articleEditStore.showFootMenu=true;
  }

  const hideInput = () => {
      let part=articleEditStore.curParts[props.idx];
      if(part.tips===null || part.tips.trim().length===0){
          part.cls="";
          tipsShow.value=false;
      }
  }

</script>

<style scoped>
.con{
  margin-top: 32px;

}
.sub{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 64px;
    width: 630px;
    box-sizing: border-box;
}
.active{
    border-left: 4px solid #FF9929;
}
.top{
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.image-row{
  width: 326px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.left-col{
  position: absolute;
  top:0;
  left: -50px;
}
.right-col{
  position: absolute;
  bottom:0;
  right: -50px;
}
.image-con{
  width: 270px;
  margin: 0 35px;
  box-sizing: border-box;
  position: relative;
}
.btn-row{
  margin-top: 10px;
  width: 520px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn{
  width: 100px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #4F4F4F;
}
.input-row{
  margin-top: 10px;
  width: 520px;
  text-align: center;
  color: #828282 ;
}
:deep(.input-row  .arco-textarea){
  font-weight: inherit ;
  font-size: 15px ;
  line-height: 21px ;
  text-align: inherit ;
  font-style:inherit;
}
.line{
  margin-top: 32px;
  width: 520px;
  height: 1px;
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: inset 0 -0.5px 0 #E7E7E7;
}
</style>
