<template>
  <div class="con">
    <div class="sub" :class="articleEditStore.curPartId===props.idx?'active':''">
        <div class="top">
            <div class="image-row">
                <div class="image-con">
                    <a-image :width="270" :height="120" fit="scale-down" :src="item.lowUrl"></a-image>
                    <div class="left-col" @click.stop="delPart">
                        <a-image :preview="false" :width="18" :height="18" src="/close.png" fit="contain" ></a-image>
                    </div>
                    <div class="right-col" >
                        <a-upload
                                action="/"
                                :multiple="false"
                                :auto-upload="false"
                                ref="uploadRef"
                                accept=".jpg, .jpeg, .png"
                                :show-file-list="false"
                                :show-remove-button="false"
                                :show-retry-button="false"
                                :show-cancel-button="false"
                                :on-before-upload="uploadImage"
                        >
                            <template #upload-button>
                                <a-image :preview="false" :width="18" :height="18" src="/switch.png" fit="contain" ></a-image>
                            </template>
                        </a-upload>
                    </div>
                </div>
            </div>
            <div class="btn-row" @click.stop="showInput" v-if=" !tipsShow && !item.tips">
                <span class="btn">添加描述</span>
            </div>
            <a-textarea
                    v-if="tipsShow || item.tips"
                    style="margin-top: 10px"
                    :model-value="item.tips"
                    @blur="hideInput"
                    @focus="onfocus"
                    ref="tips"
                    @input="textInput"
                    auto-size :class="item.cls" />
        </div>

    </div>
      <div class="line"></div>
    <a-modal v-model:visible="isShow" @ok="delOK" @cancel="delCancel">
      <template #title>
        警告
      </template>
      <div>您确定要删除该段落么？</div>
    </a-modal>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, nextTick, ref, computed} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";
import {Message} from "@arco-design/web-vue";
import {uploadRes} from "@/utils/cos";

  const articleEditStore=useArticleEditStore();
  const props = defineProps({
    idx: Number
  })
  const tips = ref(null);
  const tipsShow=ref(false)
  const  isShow=ref(false);
  const item=computed(()=>{
    return articleEditStore.curParts[props.idx]
  })
  if(item.tips!=null){
    tipsShow.value=true;
  }
  const textInput=(value,e)=>{
    let part=articleEditStore.curParts[props.idx];
    part.tips=value;
  }

  const delOK=()=>{
    isShow.value=false;
    articleEditStore.delPart(props.idx);
  }
  // 功能按钮
  const delPart = () => {
    isShow.value=true;
  }
  const delCancel = () => {
    isShow.value=false;
  }
  const uploadImage=async(file)=>{
    articleEditStore.loading=true;
    if(file.size>=1024*1024){
      Message.error("图片文件最大上传为1MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("资源上传成功");
      let url= "https://" + rst.Location;
      articleEditStore.switchImage(props.idx,url);
    }else{
      Message.error("资源上传错误");
    }
    articleEditStore.loading=false;
  }
  const showInput = (e) => {
    tipsShow.value=true;
    articleEditStore.selectPart(props.idx);
    nextTick(()=>{
        tips.value.focus()
    })
  }
  const onfocus=(e)=>{
      articleEditStore.selectPart(props.idx);
      articleEditStore.showFootMenu=true;
  }
  const hideInput = (e) => {
    let part=articleEditStore.curParts[props.idx];
    if(part.tips===null || part.tips.trim().length===0){
        part.cls="";
        tipsShow.value=false;
    }
  }
</script>

<style scoped>
.con{
  margin-top: 32px;
}
.sub{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 64px;
    width: 630px;
    box-sizing: border-box;
}
.active{
    border-left: 4px solid #FF9929;
}
.top{
  width: 520px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}
.image-row{
  width: 326px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.left-col{
  position: absolute;
  top:0;
  left: -50px;
}
.right-col{
  position: absolute;
  bottom:0;
  right: -50px;
}
.image-con{
  width: 270px;
  margin: 0 35px;
  max-height: 120px;
  box-sizing: border-box;
  position: relative;
}
.btn-row{
  margin-top: 10px;
  width: 520px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn{
  width: 100px;
  height: 30px;
  background: #FFFFFF;
  border: 1px solid #BDBDBD;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: #4F4F4F;
}
.input-row{
  margin-top: 10px;
  width: 520px;
  text-align: center;
  color: #828282 ;
  font-weight: bold ;
}
:deep(.input-row  .arco-textarea){
  font-size: 15px ;
  line-height: 21px ;
  text-align: inherit ;
  font-style:inherit;
  font-weight: inherit ;
}
.line{
  margin-top: 32px;
  width: 520px;
  height: 1px;
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: inset 0 -0.5px 0 #E7E7E7;
}
</style>
