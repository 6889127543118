<template>
  <div class="con pointer" @click.stop="itemClick">
      <div class="top" :class="articleEditStore.curPartId===props.idx?'active':''">
          <div class="sub-center">
              <div class="center-image">
                  <a-image :preview="false" :width="70" :height="70"  fit="cover" :src=" item.cover"></a-image>
              </div>
              <div class="center-text-con">
            <span class="three-line center-text" :class="item.cls">
               {{item.tips}}
            </span>
              </div>
              <div class="copy-btn">
                  <a-image :preview="false" :width="14" :height="14" src="/copy.png" fit="contain" class="other-icon pointer" @click.stop="copyBtn"></a-image>
              </div>
          </div>
          <div class="close-btn pointer">
              <a-image :preview="false" :width="8" :height="8" src="/close_no_circle.png" fit="cover" @click.stop="delBtn"></a-image>
          </div>
          <div class="up-btn pointer" v-if="props.idx>0">
              <a-image :preview="false" :width="12" :height="6" src="/arrow_down.png" fit="cover" @click.stop="upBtn"></a-image>
          </div>
          <div class=" down-btn pointer" v-if="props.idx<articleEditStore.curParts.length-1">
              <a-image :preview="false" :width="12" :height="6" src="/arrow_top.png" fit="cover" @click.stop="downBtn"></a-image>
          </div>
      </div>
    <a-modal v-model:visible="isShow" @ok="delOK" @cancel="delCancel">
      <template #title>
        警告
      </template>
      <div>您确定要删除该段落么？</div>
    </a-modal>
    <div class="down" v-if="props.idx===articleEditStore.curPartId">
        <icon-plus-circle :size="24" :stroke-width="3"  class="pointer" @click.stop="expandClick" v-if="!showMenu"/>
        <div class="down-group" v-if="showMenu">
        <div class="down-group-icon pointer">
          <a-image :preview="false" :width="20" :height="16" src="/textIcon.png" fit="contain" @click.stop="insertTextBtn"></a-image>
        </div>
        <a-upload
            action="/"
            :multiple="true"
            :limit="1"
            :auto-upload="false"
            ref="uploadImageRef"
            accept=".jpg, .jpeg, .png"
            :show-file-list="false"
            :show-remove-button="false"
            :show-retry-button="false"
            :show-cancel-button="false"
            :on-before-upload="imageUpload"
        >
          <template #upload-button>
            <div class="down-group-icon pointer">
              <a-image :preview="false" :width="20" :height="16" src="/imgIcon.png" fit="contain" ></a-image>
            </div>
          </template>
        </a-upload>
        <a-upload
            action="/"
            :multiple="true"
            :limit="1"
            :auto-upload="false"
            ref="uploadVideoRef"
            accept=".mp4"
            :show-file-list="false"
            :show-remove-button="false"
            :show-retry-button="false"
            :show-cancel-button="false"
            :on-before-upload="videoUpload"
        >
          <template #upload-button>
            <div class="down-group-icon">
              <a-image :preview="false" :width="20" :height="16" src="/videoIcon.png" fit="contain" ></a-image>
            </div>
          </template>
        </a-upload>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, reactive, watch, ref, computed, onUnmounted} from 'vue'
    import {useArticleEditStore} from "@/store/articleEdit";
import {Message} from "@arco-design/web-vue";
import {uploadRes, uploadVideoCover} from "@/utils/cos";
import {getVideoCover} from "@/utils/partTools";

  const articleEditStore=useArticleEditStore();

  const props = defineProps({
    idx: Number
  })
  const showMenu=ref(false);
  const  isShow=ref(false);
  const  popShow=ref(false);
  const item=computed(()=>{
    return articleEditStore.curParts[props.idx]
  })

  // 功能按钮
  const delOK=()=>{
    isShow.value=false;
    articleEditStore.delPart(props.idx);
  }
  // 加号站看按钮
  const expandClick=()=>{
      showMenu.value=true;
  }
  // 功能按钮
  const delBtn = () => {
    isShow.value=true;
  }
  const delCancel = () => {
    isShow.value=false;
  }
  const upBtn = () => {
    articleEditStore.upPart(props.idx);
  }
  const  downBtn= () => {
    articleEditStore.downPart(props.idx);
  }
  const  copyBtn= () => {
    articleEditStore.copyPart(props.idx);
  }

  // downBtn
  const insertTextBtn = () => {
    articleEditStore.insertTextPart(props.idx,null);
  }

  // item选中
  const itemClick=()=>{
    showMenu.value=false;
    articleEditStore.selectPart(props.idx);
  }
  // 图片上传
  const imageUpload=async(file)=>{
    if(file.size>=1024*1024){
      Message.error("图片文件最大上传为1MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("资源上传成功");
      let url= "https://" + rst.Location;
      articleEditStore.insertImagePart(props.idx,url);
    }else{
      Message.error("资源上传错误");
    }
  }
  // 视频上传
  const videoUpload=async(file)=>{
    if(file.size>=20*1024*1024){
      Message.error("视频文件最大上传为20MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("视频上传成功");
      let mp4Url= "https://" + rst.Location;
      getVideoCover(mp4Url,(file)=>{
        birthVideoCover(mp4Url,file);
      })
    }else{
      Message.error("视频上传错误");
    }
  }
  const birthVideoCover = async (mp4Url,file) => {
    let rst=await uploadVideoCover(1,file);
    if(rst.statusCode===200){
      Message.success("封面上传成功");
      let coverUrl= "https://" + rst.Location;
      articleEditStore.insertVideoPart(props.idx,mp4Url,coverUrl);
    }else{
      Message.error("封面上传错误");
    }
  }

</script>

<style scoped>
.con{
  margin-top: 20px;

}
.copy-btn{
    position: absolute;
    bottom: 3px;
    left: 3px;
}
.top{
  width: 320px;
  height: 100px;
  background: #FFFFFF;
  border:1px solid #E0E0E0;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.active{
  border: 4px solid #FF9929;
}
.close-btn{
  position: absolute;
  top:4px;
  left:3px;
  width: 16px;
  height: 16px;
}
.up-btn{
  position: absolute;
  top:4px;
  right:4px;
  width: 16px;
  height: 16px;

}
.down-btn{
  position: absolute;
  bottom:4px;
  right:4px;
  width: 16px;
  height: 16px;
}
.pop{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  width: 64px;
  height: 32px;
  position: relative;
}
.pop-text{
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}
.sub-center{
  width: 280px;
  height: 70px;
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

}

.center-image{
  width: 70px;
  height: 70px;
  background: #BDBDBD;
  border-radius: 4px;
  overflow: hidden;
}
.center-text-con{
  width: 200px;
  height: 70px;
  margin-left: 8px;
}
.center-text{
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #828282;
}

.three-line{
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
  text-align: left;
}
.down{
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.down-group{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 192px;
  height: 36px;
  background: #FFFFFF;
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.down-group-icon{
  width: 36px;
  height: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


</style>
