<template>
  <div class="left-con">
      <div class="left-list-con">
        <a-scrollbar type="track" outer-class="outer-class">
        <div v-for="(item,index) in articleListStore.articleList " :key="index">
          <ArticleItemText v-if="!item.cover" :item="item"></ArticleItemText>
          <ArticleItemCover v-if="item.cover" :item="item"></ArticleItemCover>
        </div>
        <div class="left-empty" v-if="articleListStore.total===0">
          <span class="left-empty-tips">无文章</span>
        </div>
        </a-scrollbar>
      </div>
<!--    <LeftConFoot></LeftConFoot>-->
  </div>
</template>

<script setup>
  import LeftConFoot from "@/pages/co/listCo/left_con_foot.vue";
  import ArticleItemCover from "@/pages/co/listCo/articleItem/article_Item_cover.vue";
  import ArticleItemText from "@/pages/co/listCo/articleItem/article_Item_text.vue";
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"

  const articleListStore=useArticleListStore();

</script>

<style scoped>
.left-con{
  width: 350px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  background: #F2F2F2;
}
.outer-class{
  height:600px;
  width:350px;
  overflow-x: hidden;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.left-list-con{
  width: 350px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  background: #F2F2F2;
}
.left-empty{
  width: 350px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-empty-tips{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #C0C5CE;
}
</style>
