<template>
  <div class="right-con">
    <div class="right-list-con">
      <a-scrollbar  type="track" outer-class="outer-class">
        <div class="right-list-display-con">
          <TitleEdit></TitleEdit>
          <div v-for="(item,index) in articleEditStore.curParts" :key="index">
            <TextEdit v-if="item.tp==='text'" :idx="index"></TextEdit>
            <ImageEdit v-if="item.tp==='image'" :idx="index"></ImageEdit>
            <VideoEdit v-if="item.tp==='video'" :idx="index"></VideoEdit>
          </div>
        </div>
        <ToolBar></ToolBar>
      </a-scrollbar>
    </div>
    <RightFoot></RightFoot>
  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import RightFoot from "@/pages/co/editCo/right_con_foot.vue"
  import TitleEdit from "@/pages/co/editCo/edit/title_edit.vue"
  import TextEdit from "@/pages/co/editCo/edit/text_edit.vue"
  import ImageEdit from "@/pages/co/editCo/edit/image_edit.vue"
  import VideoEdit from "@/pages/co/editCo/edit/video_edit.vue"
  import {useArticleEditStore} from "@/store/articleEdit";
  import ToolBar from "@/pages/co/editCo/edit/tool_bar.vue";

  const articleEditStore=useArticleEditStore();


  onMounted(() => { });


</script>

<style scoped>
.right-con{
  width: 650px;
  height: 650px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}
.outer-class{
  height:600px;
  width:650px;
  overflow-x: hidden;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.right-list-con{
  width: 650px;
  height: 600px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

}
.arco-scrollbar-track-direction-vertical {
  top: 0;
  right: 0;
  box-sizing: border-box;
  width: inherit !important;
  height: 100%;
}
.scroll{
  overflow-y: auto;
  overflow-x: hidden;
  width: 650px;
  box-sizing: border-box;
}
.right-list-display-con{

  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
}
</style>
