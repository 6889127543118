<template>
  <div class="base">
    <div class="bg"></div>
    <div class="loginBox">
      <a-avatar size="80" style="margin-top:49px">
        <img src="https://cheery-1304040337.cos.ap-shanghai.myqcloud.com/cheery.png" alt class="round" />
      </a-avatar>
      <div class="text-center" style="margin-top:30px">
        <p class="title">小樱桃网页编辑器</p>
      </div>
      <div style="margin-top:40px;display: flex;flex-direction: column;justify-content: flex-start;align-items: center"  >
        <div  style="height:27px;display: flex;flex-direction: row;justify-content: flex-start;align-items: center" >
          <div class="tab d-flex flex-column justify-center align-center" @click="tab=0">
            <div class="tab-text " :class="tab===0 ? 'active':''">登录</div>
            <div class="tab-bottom" v-if="tab===0"></div>
          </div>
          <div class="tab d-flex flex-column justify-center align-center" style="margin-left:23px" @click="tab=1">
            <div class="tab-text " :class="tab===1 ? 'active':''">注册</div>
            <div class="tab-bottom" v-if="tab===1"></div>
          </div>
        </div>
        <div style="width:240px;height:220px">
          <!-- 登录-->
          <div style="margin-top:10px;display: flex;flex-direction: column;justify-content: flex-start;align-items: center"  v-if="tab===0">
            <input class="inputBox" type="text" style="margin-top:24px;width:240px" placeholder="用户名" v-model="userStore.loginReq.loginPhone">
            <input class="inputBox" type="password" style="margin-top:24px;width:240px" placeholder="密码" v-model="userStore.loginReq.loginPwd">
            <div  style="margin-top:20px;width:240px;display: flex;flex-direction: row;align-items: center;justify-content: space-between">
              <input class="inputBox" type="text" style="width:150px" placeholder="输入验证码" v-model="userStore.loginReq.imageCode">
              <a-image class="codeZone" height="32" width="80"  :preview="false" :src="userStore.imageCodeFile" @click.stop="userStore.getImgCode"></a-image>
            </div>
            <a-button class="loginBtn pointer" style="margin-top:20px" @click="userStore.userLogin">登录</a-button>
          </div>
          <!-- 注册-->
          <div style="margin-top:10px;display: flex;flex-direction: column;justify-content: flex-start;align-items: center"  v-if="tab===1">
            <input class="inputBox" style="margin-top:10px;width:240px" placeholder="手机号码" v-model="userStore.regReq.regPhone">
            <div style="margin-top:10px;width:240px;display: flex;flex-direction: row;align-items: center;justify-content: space-between">
              <input class="inputBox" type="text" style="width:150px" placeholder="验证码" v-model="userStore.regReq.smsCode">
              <a-button class="msgBtn" @click="smsCodeClick" :disabled="smsDisable">{{smsText}}</a-button>
            </div>
            <input class="inputBox" type="password" style="margin-top:10px;width:240px" placeholder="输入密码" v-model="userStore.regReq.regPwd">
            <input class="inputBox" type="password" style="margin-top:10px;width:240px" placeholder="再次输入密码" v-model="userStore.regReq.repeatPwd">
            <a-button class="loginBtn pointer" style="margin-top:15px" @click="userStore.userReg">注册</a-button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center copyrightBox">
      <p class="copyright">© 2020 -2022 smartcheery Inc. All Rights Reserved</p>
    </div>
  </div>
</template>

<script setup>
  import {onMounted, reactive, ref} from "vue";
  import {useUserStore} from "@/store/user";
  const userStore=useUserStore();
  const tab=ref(0)
  const smsText=ref("验证码");
  const smsDisable=ref(false);
  onMounted(()=>{
    userStore.getImgCode();
  })
  let count=60;
  const smsCodeClick=async()=>{
    await userStore.sendSms();
    smsDisable.value=true;
    let timer=setInterval(() => {
      count--;
      if (count>0) {
        smsText.value = count + "s";
      } else {
        smsText.value = "获取验证码";
        smsDisable.value = false;
        count=60;
        clearInterval(timer);
      }
    }, 1000);
  }

</script>

<style scoped>
.base{
  width:100vw;
  height:100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.bg {
  position: relative;
  top: 0;
  left: 0;
  background-image: url("https://cheery-1304040337.cos.ap-shanghai.myqcloud.com/Pic.png") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  -webkit-filter: blur(64px);
  -moz-filter: blur(64px);
  -o-filter: blur(64px);
  -ms-filter: blur(64px);
  filter: blur(75px);
}

.tab{
  width: 56px;
  height: 27px;
}

.tab-text{
  color: rgba(0, 0, 0, 0.5);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  width:100%;
  height: 27px;
  width: 56px;

}
.tab-text.active{
  color: #000000;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  height: 20px;
  width: 56px;
}
.tab-bottom{
  background: #ED7E5B !important;
  border-radius: 2px !important;
  height: 4px !important;
  width:42px;
  margin-bottom:0px;
  margin-left:auto;
  margin-right:auto;
}

.loginBox {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 380px;
  height: 540px;
  background: #ffffff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}
.codeZone{
  height: 32px;
  background: #FFFFFF;
  width:80px;
}
.msgBtn{
  height: 32px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  font-style: normal;
  font-weight: normal;
  line-height: 0px;
  width:80px;
  font-size: 12px !important;
  color: rgba(0, 0, 0, 0.5);
}
button{
  outline:none;
}
.loginBtn{
  width: 160px;
  height: 40px;
  background: #ED7E5B;
  border-radius: 8px;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height:40px;
}


.inputBox{
  outline-style: none ;
  height: 32px;
  background: #F3F3F3;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 10px;
  color: rgba(0, 0, 0, 0.35);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;

}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 0px;
}
.copyrightBox {
  position: fixed;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 16px;
  width: 380px;
}
.copyright {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  /* or 145% */
  color: rgba(255, 255, 255, 0.7);
}
</style>
