import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import UserLogin from '@/pages/user_login.vue'
import ArticleList from '@/pages/article_list.vue'
import ArticleEditor from '@/pages/article_editor.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: UserLogin,
  },
  {
    path: '/list',
    name: 'articleList',
    component: ArticleList,
  },
  {
    path: '/editor',
    name: 'articleEditor',
    component: ArticleEditor,
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
