<template>
  <div class="con">
    <div class="cover" v-if="articleEditStore.curArt.cover">
      <a-popover position="rt"    :arrow-style="{'visibility':'hidden'}"
                 :content-style="{'padding': '0','margin': '0','left':'-50px','top':'40px'}" >
        <div class="cover-image">
          <a-image :preview="false" :width="100" :height="70" fit="cover" :src="articleEditStore.curArt.cover"></a-image>
        </div>
        <template #content>
          <div class="pop-con">
            <a-upload
                action="/"
                :multiple="false"
                class="pop-top pointer"
                :limit="1"
                :file-list="fileList"
                :auto-upload="false"
                ref="switchCoverRef"
                accept=".jpg, .jpeg, .png"
                :show-file-list="false"
                :show-remove-button="false"
                :show-retry-button="false"
                :show-cancel-button="false"
                :on-before-upload="selectCover"
            >
              <template #upload-button>
                <div><span>更换</span></div>
              </template>
            </a-upload>
            <div class="pop-line"></div>
            <div class="pop-down pointer" @click.stop="delCover"><span>删除</span></div>
          </div>
        </template>
      </a-popover>
      <a-textarea :model-value="articleEditStore.curArt.title" auto-size class="cover-title" @input="titleInput"/>
    </div>

    <div class="uncover" v-if="articleEditStore.curArt.cover==null">
      <a-textarea :model-value="articleEditStore.curArt.title"
                  placeholder="输入标题"
                  auto-size class="uncover-text"
                  style="text-align: center" @input="titleInput"/>
      <div class="uncover-btn-row">
        <a-upload
            action="/"
            :multiple="false"
            :limit="1"
            :auto-upload="false"
            ref="selectCoverRef"
            accept=".jpg, .jpeg, .png"
            :show-file-list="false"
            :show-remove-button="false"
            :show-retry-button="false"
            :show-cancel-button="false"
            :on-before-upload="imageUpload"
        >
          <template #upload-button>
            <div class="uncover-btn pointer" >选择封面</div>
          </template>
        </a-upload>
      </div>
    </div>
    <div class="line"></div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from 'vue'
import {useArticleEditStore} from "@/store/articleEdit";
import {uploadRes} from "@/utils/cos";
import {Message} from "@arco-design/web-vue";

const articleEditStore=useArticleEditStore();
  const fileList=ref([]);
  onMounted(() => {});

  const imageUpload=async (file)=>{
    if(file.size>=1024*1024){
      Message.error("图片文件最大上传为1MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("资源上传成功");
      let coverUrl= "https://" + rst.Location;
      articleEditStore.uploadCover(coverUrl);
    }else{
      Message.error("资源上传错误");
    }
  }

  const titleInput=(value,e)=>{
    articleEditStore.titleEdit(value);
  }
  const selectCover = async(file) => {
    articleEditStore.loading=true;
    if(file.size>=1024*1024){
      Message.error("图片文件最大上传为1MB;当前文件已超过大小");
    }
    let rst=await uploadRes(1,file);
    if(rst.statusCode===200){
      Message.success("资源上传成功");
      let coverUrl= "https://" + rst.Location;
      articleEditStore.uploadCover(coverUrl);
    }else{
      Message.error("资源上传错误");
    }
    articleEditStore.loading=false;
  }

  const delCover = () => {
    articleEditStore.delCover()
  }

</script>

<style scoped lang="less">
.con{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 520px;
  margin-top: 32px;
  background-color: #FFFFFF;
}
.pop-con{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  position: relative;
  width: 88px;
  height: 89px;
  background: #FFFFFF;
  border: 0.5px solid #828282;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
.pop-top{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  width: 64px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #333333;
}
.pop-line{
  width: 64px;
  height: 1px;
  background: #C6C6C8;

}
.pop-down{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  width: 64px;
  height: 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  color: #EB5757;
}
.line{
  margin-top: 32px;
  width: 520px;
  height: 1px;
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: inset 0px -0.5px 0px #E7E7E7;
}
.uncover{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 520px;
  height: 100px;
  padding: 15px;
  background: #FFFFFF;
}
.cover{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 520px;
  height: 100px;
  padding: 15px;
  background: #FFFFFF;
  box-sizing: border-box;
}
.uncover-text{
  width: 375px;
  font-style: normal;
  font-weight: 400;
  //color: #BDBDBD ;
  color: #333333 ;
}
:deep(.uncover-text .arco-textarea){
  text-align: inherit ;
  font-style:inherit;
  font-weight: inherit ;
  font-size: 20px ;
  line-height: 24px ;
}
.uncover-btn-row{
  margin-top: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.uncover-btn{
  width: 100px;
  height: 32px;
  border: 1px solid #BDBDBD;
  border-radius: 16px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #4F4F4F;
}
.cover-image{
  width: 100px;
  height: 70px;
  background: #FFFFFF;
  border-radius: 6px;
  overflow: hidden;
}
.cover-title{
  width: 378px;
  height: 70px;
  margin-left: 12px;
  font-style: normal;
  text-align: left;
  font-weight: 400 ;
  color: #333333 ;
}
:deep(.cover-title .arco-textarea){
  text-align: inherit ;
  font-style:inherit;
  font-weight: inherit ;
  font-size: 20px ;
  line-height: 24px ;

}
</style>
