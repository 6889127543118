<template>
  <div class="con" >
      <div class="sub" :class="articleEditStore.curPartId===props.idx?'active':''">
          <a-textarea
            :model-value="item['content']"
            @input="textInput"
            :autofocus="false"
            @blur="onBlur"
            @focus="onFocus"
            ref="textArea"
            :auto-size="{minRows:2,}"
            :class="item.cls"
            class="text"/>
      </div>

    <div class="line"></div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, watch, reactive, computed, ref} from 'vue'
  import {useArticleEditStore} from "@/store/articleEdit";

  const articleEditStore=useArticleEditStore();
  const textArea = ref(null);
  const text=ref(null);
  const props = defineProps({
    idx: Number
  })

  const item=computed(()=>{
    return articleEditStore.curParts[props.idx]
  })
  const textInput=(value,e)=>{
    let part=articleEditStore.curParts[props.idx];
    part.content=value;
  }
  const onFocus=()=>{
    articleEditStore.showFootMenu=true;
    articleEditStore.selectPart(props.idx)
    //let part=articleEditStore.curParts[props.idx];
  }
  const onBlur=(e)=>{
    //articleEditStore.showFootMenu=false;
    let part=articleEditStore.curParts[props.idx];
    if(part.content ==null || part.content.trim().length===0){
      articleEditStore.delPart(props.idx);
    }
  }

</script>

<style scoped>
.con{
  margin-top: 32px;

}
.sub{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 64px;
    width: 630px;
    box-sizing: border-box;
}
.active{
    border-left: 4px solid #FF9929;
}
.text{
  color: #222222 ;
  font-weight: 400 ;
}
:deep(.text .arco-textarea){
  text-align: inherit ;
  font-style:inherit;
  font-weight: inherit ;

  font-size: 17px ;
  line-height: 29px ;
}

.line{
  margin-top: 32px;
  width: 520px;
  height: 1px;
  background: rgba(0, 0, 0, 0.0001);
  box-shadow: inset 0px -0.5px 0px #E7E7E7;
}
</style>
