<template>
  <div class="item-card pointer"
       @click.stop="selectArticle"
       :class="articleListStore.curArticle!==null && articleListStore.curArticle.id===props.item.id?'active':''">
    <div class="item-card-sub">
      <div class="item-image">
        <a-image :preview="false" fit="cover" :width="100" :height="70"
                 :src="item['cover']"
        ></a-image>
      </div>
      <div class="item-text">
        <div class="sub-title-con">
          <span class="sub-title two-line">{{item['title']}}</span>
        </div>

        <div class="sub-tips-con">
<!--          <span class="sub-tips">作者</span>-->
          <span class="sub-tips-second">{{createAt}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {defineProps, defineEmits, onMounted, watch, reactive, ref, computed} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
import dayjs from "dayjs";
import {parseDate} from "@/utils/helper";

  const articleListStore=useArticleListStore();
  const props = defineProps({
    item: Object
  });

  const createAt=computed(()=>{
      return parseDate(props.item.exportAt)
    //return dayjs(props.item.exportAt).format('YYYY-MM-DD').toString()
  })
  const selectArticle=()=>{
    articleListStore.curDetail(props.item)
  }
</script>

<style scoped>
.item-card{
  padding: 15px;
  width: 320px;
  height: 108px;
  background: #FFFFFF;
  border-radius: 10px;
  margin-top: 8px;
  box-sizing:border-box;
}

.item-card-sub{
  width: 290px;
  height: 78px;
  background: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.item-image{
  width: 100px;
  height: 70px;
  background: #FFFFFF;
  border-radius: 6px;
  overflow: hidden;
}
.item-text{
  width: 180px;
  height: 70px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.sub-title-con{
  height: 40px;
}
.sub-title{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
}
.two-line{
  overflow:hidden;
  text-overflow:ellipsis;
  display:-webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
}
.sub-tips-con{
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sub-tips{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
}
.sub-tips-second{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #828282;
  margin-left: 12px;
}
.active{
  border: 4px solid #FF9929;
}
</style>
