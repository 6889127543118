<template>
  <div class="left-con">
    <LeftHeader v-if="articleEditStore.curParts.length !== 0"></LeftHeader>
    <a-scrollbar type="track" outer-class="outer-class" >
      <div v-for="(item,index) in articleEditStore.curParts" :key="index">
        <TextPart :idx="index" v-if="item.tp==='text'"></TextPart>
        <ImagePart :idx="index" v-if="item.tp==='image'"></ImagePart>
        <VideoPart :idx="index" v-if="item.tp==='video'"></VideoPart>
      </div>
      <div class="left-empty" v-if="articleEditStore.curParts.length===0">
        <span class="left-empty-tips">无段落</span>
      </div>
    </a-scrollbar>

  </div>
</template>

<script setup>
  import {  defineProps, defineEmits,onMounted} from 'vue'
  import {useArticleListStore} from "@/store/articleList"
  import TextPart from "@/pages/co/editCo/part/text_part.vue";
  import ImagePart from "@/pages/co/editCo/part/image_part.vue";
  import VideoPart from "@/pages/co/editCo/part/video_part.vue";
  import {useArticleEditStore} from "@/store/articleEdit";
  import LeftHeader from "@/pages/co/editCo/left_con_header.vue";

  const articleEditStore=useArticleEditStore();

  onMounted(() => { });

</script>

<style scoped>
.left-con{
  width: 350px;
  height: 650px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  background: #F2F2F2;
}
.outer-class{
  height:600px;
  width:350px;
  overflow-x: hidden;
  overflow-y: auto;
  display:flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.left-list-con{
  width: 350px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  background: #F2F2F2;
}
.left-empty{
  width: 350px;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.left-empty-tips{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #C0C5CE;
}

</style>
